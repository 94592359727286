import React from "react";
import ScreenTemplate from "../patterns/screenTemplate";

const Tutorials = () => {
  return (
    <ScreenTemplate>
      <div className="tutorials_screen">Comming soon.</div>
    </ScreenTemplate>
  );
};

export default Tutorials;
